import { useEffect, useRef, useState } from "react";
import { getOffsetProc } from "../chessUtils/util";
import styles from "./Piece.module.css";
import { ANIMATION_DURATION, Z_OVER } from "./animations";
import React from "react";
import { COLOR_LETTERS, PIECE_LETTERS } from "../chessUtils/fen";

function easeInOutQuad(
  elapsed: number,
  initialValue: number,
  goal: number,
  duration: number
): number {
  if ((elapsed /= duration / 2) < 1) {
    return ((goal - initialValue) / 2) * elapsed * elapsed + initialValue;
  }
  return (
    (-(goal - initialValue) / 2) * (--elapsed * (elapsed - 2) - 1) +
    initialValue
  );
}

function rafAnimation(start, goal, piece, animating) {
  if (!piece) {
    return;
  }
  const startTime = performance.now();
  loop();

  function loop() {
    const time = performance.now() - startTime;

    if (!animating.current) {
      return;
    }

    if (time < ANIMATION_DURATION) {
      let x = easeInOutQuad(time, start.x, goal.x, ANIMATION_DURATION);
      let y = easeInOutQuad(time, start.y, goal.y, ANIMATION_DURATION);
      piece.style.transform = `translate(${x}%, ${y}%)`;
      requestAnimationFrame(loop);
    } else {
      piece.style.transform = `translate(${goal.x}%, ${goal.y}%)`;
    }
  }
}

function Piece({
  piece,
  currentKey,
  pieceRefs,
  canDrag,
  playingWhite,
  animate,
  zIndex,
  chessSet,
}) {
  const { id, role, color } = piece;
  const lastKey = useRef(currentKey);
  const animating = useRef(animate);
  const animRef = useRef(null);

  const [offset, setOffset] = useState(getOffsetProc(currentKey, playingWhite));
  useEffect(() => {
    if (lastKey != currentKey && animate) {
      const piece = pieceRefs.current[id];
      if (!piece) {
        return;
      }
      animating.current = true;
      const start = getOffsetProc(lastKey.current, playingWhite);
      const goal = getOffsetProc(currentKey, playingWhite);

      // if (typeof piece.animate == "function") {
      //   animRef.current = piece.animate(
      //     { transform: `translate(${goal.x}%, ${goal.y}%)` },
      //     {
      //       duration: ANIMATION_DURATION,
      //       easing: "ease-in-out",
      //     }
      //   );

      //   animRef.current.onfinish = () => {
      //     piece.style.transform = `translate(${goal.x}%, ${goal.y}%)`;
      //   };
      // } else {
      rafAnimation(start, goal, piece, animating);
      // }
    } else {
      const goal = getOffsetProc(currentKey, playingWhite);
      setOffset(goal);
    }
    lastKey.current = currentKey;
  }, [currentKey, playingWhite]);

  useEffect(() => {
    if (!animate) {
      animating.current = false;
      if (animRef.current) {
        animRef.current.cancel();
      }
      const goal = getOffsetProc(currentKey, playingWhite);
      const piece = pieceRefs.current[id];
      if (piece) {
        piece.style.transform = `translate(${goal.x}%, ${goal.y}%)`;
      }
    }
  }, [animate]);

  const classes = [];
  const pieceCode = COLOR_LETTERS[color] + PIECE_LETTERS[role];
  classes.push(styles.piece);
  classes.push(styles[`${chessSet}_${pieceCode}`]);
  if (!canDrag) {
    classes.push(styles.opponent);
  }

  return (
    <div
      key={piece.id}
      ref={(element) => {
        pieceRefs.current[id] = element;
      }}
      style={{
        zIndex,
        transform: `translate(${offset.x}%, ${offset.y}%)`,
      }}
      className={classes.join(" ")}
    ></div>
  );
}

export default Piece;
