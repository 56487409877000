import { getPieceId } from "../chessUtils/fen";
import {
  key2pos,
  allKeys,
  distance,
  containsX,
  getOffsetProc,
} from "../chessUtils/util";
// import { addSound } from "./Board";

export const ANIMATION_DURATION = 370;
export const Z_OVER = 30;
export const Z_UNDER = 20;

function makePiece(k, piece) {
  return {
    key: k,
    pos: key2pos(k),
    role: piece.role,
    color: piece.color,
    id: piece.id,
  };
}

function samePiece(p1, p2) {
  return p1.role === p2.role && p1.color === p2.color;
}

function closer(piece, pieces) {
  return pieces.sort(function (p1, p2) {
    return distance(piece.pos, p1.pos) - distance(piece.pos, p2.pos);
  })[0];
}

export function computeAnims(prev, current, playingWhite, lastMove) {
  let moves = {},
    movesOrigs = [],
    fadeIns = [],
    fadeOuts = [],
    missings = [],
    news = [],
    prePieces = {},
    curPieces = {},
    piece,
    hasCapture = false,
    toBoard = {};

  // create previous pieces
  for (let pk in prev) {
    piece = makePiece(pk, prev[pk]);
    prePieces[piece.key] = piece;
  }

  // create current pieces
  for (let ck in current) {
    piece = makePiece(ck, current[ck]);
    curPieces[piece.key] = piece;
  }

  for (let i = 0; i < allKeys.length; i++) {
    let key = allKeys[i];
    let curP = curPieces[key];
    let preP = prePieces[key];
    if (curP) {
      if (preP) {
        if (!samePiece(curP, preP)) {
          missings.push(preP);
          news.push(makePiece(key, curP));
        } else {
          toBoard[key] = preP;
        }
      } else news.push(makePiece(key, curP));
    } else if (preP) missings.push(preP);
  }

  function addMove(preP, newP) {
    const from = preP.key;
    const to = newP.key;
    const id = prev[from].id;
    const start = getOffsetProc(from, playingWhite);
    const goal = getOffsetProc(to, playingWhite);
    const move = { start, goal, id, to, from };

    toBoard[to] = prev[from];
    if (prev[move.to]) {
      hasCapture = true;
    }

    moves[id] = move;
    movesOrigs.push(preP.key);
  }

  news.forEach((newP) => {
    const preP = closer(
      newP,
      missings.filter((p) => samePiece(newP, p))
    );
    if (preP && !containsX(movesOrigs, preP.key)) {
      addMove(preP, newP);
    } else {
      if (
        !lastMove?.promotion ||
        (lastMove?.promotion && lastMove.to != newP.key)
      ) {
        fadeIns.push(newP);
      }
    }
  });

  if (lastMove?.promotion) {
    const pawn = prev[lastMove.from];
    if (pawn) {
      const prePiece = makePiece(lastMove.from, pawn);
      const curPiece = makePiece(lastMove.to, pawn);

      addMove(prePiece, curPiece);
    }
    const promoPiece = current[lastMove.to];
    if (promoPiece) {
      const p = makePiece(lastMove.to, promoPiece);
      p.id = p.color + p.role + Date.now();
      toBoard[lastMove.to] = p;
    }
  }

  const pieceIds =
    fadeIns.length && Object.values(prev).map((piece: any) => piece.id);

  for (let i in fadeIns) {
    const fade = fadeIns[i];
    fade.id = getPieceId(pieceIds, fade.color + fade.role, 0);
    pieceIds.push(fade.id);
    toBoard[fade.key] = fade;
  }

  missings.forEach((p) => {
    if (!containsX(movesOrigs, p.key)) {
      fadeOuts.push(p);
    }
  });

  return {
    moves,
    fadeIns,
    fadeOuts,
    toBoard,
    hasCapture,
  };
}

// function easeInOutQuad(
//   elapsed: number,
//   initialValue: number,
//   goal: number,
//   duration: number
// ): number {
//   if ((elapsed /= duration / 2) < 1) {
//     return ((goal - initialValue) / 2) * elapsed * elapsed + initialValue;
//   }
//   return (
//     (-(goal - initialValue) / 2) * (--elapsed * (elapsed - 2) - 1) +
//     initialValue
//   );
// }

// function animMove(time, move) {
//   let x = easeInOutQuad(time, move.start.x, move.goal.x, ANIMATION_DURATION);
//   let y = easeInOutQuad(time, move.start.y, move.goal.y, ANIMATION_DURATION);
//   return { x, y };
// }

// function setZIndex(moveAnims, zIndex, pieceRefs) {
//   moveAnims.forEach((move) => {
//     if (move.id) {
//       const pieceNode = pieceRefs.current[move.id];
//       if (pieceNode) {
//         pieceNode.style.zIndex = zIndex;
//       }
//     }
//   });
// }

// function rafAnimation({
//   moves,
//   pieceRefs,
//   dispatch,
//   toBoard,
//   hasCapture,
//   animating,
// }) {
//   setZIndex(moves, Z_OVER, pieceRefs);

//   const startTime = performance.now();
//   loop();

//   function loop() {
//     if (!animating.current) {
//       addSound(hasCapture);
//       return;
//     }
//     const time = performance.now() - startTime;

//     if (time < ANIMATION_DURATION) {
//       moves.forEach((move) => {
//         if (move.id) {
//           const { x, y } = animMove(time, move);
//           const pieceNode = pieceRefs.current[move.id];
//           if (pieceNode) {
//             pieceNode.style.transform = `translate(${x}%, ${y}%)`;
//           }
//         }
//       });

//       requestAnimationFrame(loop);
//     } else {
//       addSound(hasCapture);
//       dispatch({ type: "setPosition", position: toBoard });
//     }
//   }
// }

// export function animatePosition(animProps) {
//   const { animating, animatingToBoard, dispatch } = animProps;
//   if (animating.current) {
//     animating.current = false;
//     dispatch({
//       type: "setPosition",
//       position: animatingToBoard.current,
//     });
//     animProps.position = animatingToBoard.current;
//     requestAnimationFrame(() => {
//       animatePieces(animProps);
//     });
//   } else {
//     animatePieces(animProps);
//   }
// }

// function animatePieces({
//   dispatch,
//   animating,
//   animatingToBoard,
//   pieceRefs,
//   anims,
//   position,
//   newPosition,
//   playingWhite,
//   lastMove,
// }) {
//   const { hasCapture, toBoard, fadeIns, moves } = computeAnims(
//     position,
//     newPosition,
//     playingWhite,
//     lastMove
//   );
//   animating.current = true;
//   animatingToBoard.current = toBoard;

//   const animParams = {
//     moves,
//     pieceRefs,
//     anims,
//     dispatch,
//     toBoard,
//     hasCapture,
//     animating,
//     lastMove,
//   };

//   if (fadeIns.length) {
//     dispatch({ type: "setNewPieces", newPieces: fadeIns });
//   }

//   if (typeof Element.prototype.animate == "function") {
//     animation(animParams);
//   } else {
//   rafAnimation(animParams);
//   }
// }

// function animation({ moves, pieceRefs, dispatch, toBoard, hasCapture, anims }) {
//   setZIndex(moves, Z_OVER, pieceRefs);

//   let didEnd = false;
//   moves.forEach((move) => {
//     if (move.id) {
//       const pieceNode = pieceRefs.current[move.id];

//       if (pieceNode) {
//         const anim = pieceNode.animate(
//           [
//             { transform: `translate(${move.start.x}%, ${move.start.y}%)` },
//             { transform: `translate(${move.goal.x}%, ${move.goal.y}%)` },
//           ],
//           {
//             duration: ANIMATION_DURATION,
//             easing: "ease-in-out",
//           }
//         );
//         anim.onfinish = () => {
//           pieceNode.style.transform = `translate(${move.goal.x}%, ${move.goal.y}%)`;

//           if (!didEnd) {
//             addSound(hasCapture);
//             dispatch({ type: "setPosition", position: toBoard });
//             didEnd = true;
//             delete anims[move.id];
//           }
//         };

//         anims[move.id] = anim;
//       }
//     }
//   });
// }
