import { pos2key, invRanks, ranks } from "./util";

export const initial =
  "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

const roles = {
  p: "pawn",
  r: "rook",
  n: "knight",
  b: "bishop",
  q: "queen",
  k: "king",
};

const letters = {
  pawn: "p",
  rook: "r",
  knight: "n",
  bishop: "b",
  queen: "q",
  king: "k",
};

export const COLOR_LETTERS = {
  white: "w",
  black: "b",
};

export const PIECE_LETTERS = {
  pawn: "P",
  rook: "R",
  knight: "N",
  bishop: "B",
  queen: "Q",
  king: "K",
};

export function getPieceId(pieceIds, piece, i) {
  const newId = piece + i;
  if (pieceIds.indexOf(newId) >= 0) {
    return getPieceId(pieceIds, piece, i + 1);
  } else {
    return newId;
  }
}

export function read(fen) {
  let pieceIds = [];
  if (fen === "start") fen = initial;
  const pieces = {};
  fen
    .replace(/ .+$/, "")
    .split("/")
    .forEach(function (row, y) {
      var x = 0;
      row.split("").forEach(function (v) {
        var nb = parseInt(v);
        if (nb) x += nb;
        else {
          x++;
          const role = roles[v.toLowerCase()];
          const color = v === v.toLowerCase() ? "black" : "white";
          const id = getPieceId(pieceIds, color + role, 0);
          pieceIds.push(id);
          pieces[pos2key([x, 8 - y])] = {
            role: role,
            color: color,
            id: id,
          };
        }
      });
    });

  return pieces;
}

export function write(pieces) {
  return [8, 7, 6, 5, 4, 3, 2].reduce(
    function (str, nb: any) {
      return str.replace(new RegExp(Array(nb + 1).join("1"), "g"), nb);
    },
    invRanks
      .map(function (y) {
        return ranks
          .map(function (x) {
            var piece = pieces[pos2key([x, y])];
            if (piece) {
              var letter = letters[piece.role];
              return piece.color === "white" ? letter.toUpperCase() : letter;
            } else return "1";
          })
          .join("");
      })
      .join("/")
  );
}
