import { ChessSets } from "@chesshotel/constants";
import Piece from "./Piece";
import { Z_OVER, Z_UNDER } from "./animations";
import React from "react";

interface PieceData {
  id: string;
  color: string;
  role: string;
}

export default function Pieces({
  position,
  freeMove,
  // newPieces,
  fadeOuts = [],
  pieceRefs,
  playingWhite,
  animate = false,
  moves = {},
  chessSet = ChessSets.CHESSHOTEL,
}) {
  function renderPiece(piece: PieceData, key: string, zIndex) {
    return (
      <Piece
        key={piece.id}
        piece={piece}
        currentKey={key}
        pieceRefs={pieceRefs}
        playingWhite={playingWhite}
        animate={animate}
        zIndex={zIndex}
        chessSet={chessSet}
        canDrag={
          freeMove ||
          (playingWhite && piece.color === "white") ||
          (!playingWhite && piece.color === "black")
        }
      />
    );
  }

  const pieces = [];

  for (let key in position) {
    const piece = position[key];
    if (piece) {
      const zIndex = moves[piece.id] ? Z_OVER : Z_UNDER;
      pieces.push(renderPiece(piece, key, zIndex));
    }
  }

  fadeOuts.forEach((piece) => {
    if (piece) {
      pieces.push(renderPiece(piece, piece.key, Z_UNDER));
    }
  });
  return <>{pieces}</>;
}
