export const files = "abcdefgh".split("");
export const invFiles = "hgfedcba".split("");
export const ranks = [1, 2, 3, 4, 5, 6, 7, 8];
export const invRanks = [8, 7, 6, 5, 4, 3, 2, 1];



export function pos2key(pos) {
  return files[pos[0] - 1] + pos[1];
}

export function key2pos(pos) {
  return [files.indexOf(pos[0]) + 1, parseInt(pos[1])];
}

export function invertKey(key) {
  return files[7 - files.indexOf(key[0])] + (9 - parseInt(key[1]));
}

// export function getPos(x, y, playingWhite) {
//   let xVal = playingWhite ? x + 1 : 8 - x;
//   let yVal = playingWhite ? 8 - y : y + 1;
//   return pos2key([xVal, yVal]);
// }

export function getPosFromKey(key, playingWhite) {
  const [x, y] = key2pos(key);
  const xVal = playingWhite ? x - 1 : 8 - x;
  const yVal = playingWhite ? 8 - y : y - 1;
  return [xVal, yVal];
}

export function getOffset(
  posKey: string,
  playingWhite: boolean,
  squareSize: number
) {
  const currentPos = key2pos(posKey);
  const xVal = playingWhite ? currentPos[0] - 1 : 8 - currentPos[0];
  const yVal = playingWhite ? 8 - currentPos[1] : currentPos[1] - 1;

  const x = Math.round(xVal * squareSize);
  const y = Math.round(yVal * squareSize);

  return { x, y };
}

export function getOffsetProc(posKey: string, playingWhite: boolean) {
  let [x, y] = key2pos(posKey);
  x = playingWhite ? x - 1 : 8 - x;
  y = playingWhite ? 8 - y : y - 1;

  x = x * 100;
  y = y * 100;

  return { x, y };
}

export function offsetToPos(
  x: number,
  y: number,
  playingWhite: boolean,
  squareSize: number
) {
  x = Math.floor(x / squareSize);
  y = Math.floor(y / squareSize);

  x = playingWhite ? x + 1 : 8 - x;
  y = playingWhite ? 8 - y : y + 1;

  return [x, y];
}

export function offsetToKey(
  x: number,
  y: number,
  playingWhite: boolean,
  squareSize: number
) {
  const pos = offsetToPos(x, y, playingWhite, squareSize);
  return pos2key(pos);
}

export const allPos = (function () {
  const ps = [];
  invRanks.forEach(function (y) {
    ranks.forEach(function (x) {
      ps.push([x, y]);
    });
  });
  return ps;
})();
export const invPos = allPos.slice().reverse();
export const allKeys = allPos.map(pos2key);

export function classSet(classes) {
  const arr = [];
  for (const i in classes) {
    if (classes[i]) arr.push(i);
  }
  return arr.join(" ");
}

export function opposite(color) {
  return color === "white" ? "black" : "white";
}

export function contains2(xs, x) {
  return xs && (xs[0] === x || xs[1] === x);
}

export function containsX(xs, x) {
  return xs && xs.indexOf(x) !== -1;
}

export function distance(pos1, pos2) {
  return Math.sqrt(
    Math.pow(pos1[0] - pos2[0], 2) + Math.pow(pos1[1] - pos2[1], 2)
  );
}

// this must be cached because of the access to document.body.style
let cachedTransformProp;

export function computeTransformProp() {
  return "transform" in document.body.style
    ? "transform"
    : "webkitTransform" in document.body.style
    ? "webkitTransform"
    : "mozTransform" in document.body.style
    ? "mozTransform"
    : "oTransform" in document.body.style
    ? "oTransform"
    : "msTransform";
}

export function transformProp() {
  if (!cachedTransformProp) cachedTransformProp = computeTransformProp();
  return cachedTransformProp;
}

export function translate(pos) {
  return "translate3d(" + pos[0] + "px," + pos[1] + "px,0)";
}

export function eventPosition(e) {
  return e.touches
    ? [e.targetTouches[0].clientX, e.targetTouches[0].clientY]
    : [e.clientX, e.clientY];
}

export function isRightButton(e) {
  return e.buttons === 2 || e.button === 2;
}

export function stringToMove(moveStr) {
  return (
    moveStr && {
      from: moveStr.substring(0, 2),
      to: moveStr.substring(2, 4),
    }
  );
}

export function convertMoves(moves) {
  const convertedMoves = {};
  moves.forEach((move) => {
    if (convertedMoves[move.from]) {
      convertedMoves[move.from].push(move.to);
    } else {
      convertedMoves[move.from] = [move.to];
    }
  });
  return convertedMoves;
}

// module.export = {
//   files: files,
//   ranks: ranks,
//   invRanks: invRanks,
//   allPos: allPos,
//   invPos: invPos,
//   allKeys: allKeys,
//   pos2key: pos2key,
//   key2pos: key2pos,
//   invertKey: invertKey,
//   classSet: classSet,
//   opposite: opposite,
//   translate: translate,
//   contains2: contains2,
//   containsX: containsX,
//   distance: distance,
//   eventPosition: eventPosition,
//   transformProp: transformProp,
//   requestAnimationFrame: (window.requestAnimationFrame || window.setTimeout).bind(window),
//   isRightButton: isRightButton,
// };
